<template>
  <el-card class="box-card" shadow="never" style="min-height: 750px">
    <el-card class="card-all" v-for="item in individualBusinessList" :key="item.id" >
    <div @click="handleClick(item)">
        <div class="card-certification">
            <span>{{item.firmId?item.firmId:'-'}}</span>
            <span v-if="item.status=='stay_authenticate' ">待认证</span>
            <span v-if="item.status=='identity_success' || item.status=='identity_failure' || item.status=='real_failure'">认证失败</span>
            <span v-if="item.status=='real_success'" >待视频认证</span>
            <span v-if="item.status=='face_success'">待申请</span>
            <span v-if="item.status=='face_failure' ">视频认证未通过</span>
            <span v-if="item.status=='stay_audit' || item.status=='auditing'||item.status=='stay_government_audit'">审核中</span>
            <span v-if="item.status=='pass_audit'">注册成功</span>
            <span v-if="item.status=='reject_audit'">注册失败</span>
            <span v-if="item.status=='company_logoff_stay_audit'">企业注销待审核</span>
            <span v-if="item.status=='company_logoff_reject'">企业注销驳回</span>
            <span v-if="item.status=='individual_logoff_stay_apply'">个体注销待申请</span>
            <span v-if="item.status=='individual_logoff_stay_audit'">个体注销待审核</span>
            <span v-if="item.status=='individual_logoff_reject'">个体注销驳回</span>
            <span v-if="item.status=='logoff_ing'">注销中</span>
            <span v-if="item.status=='logoff_complete'">已注销</span>

        </div>
        <div class="card-item">
            <label>个体户编号：</label>
            <span>{{item.firmId?item.firmId:'-'}}</span>
        </div>
        <div class="card-item">
            <label>营业执照号：</label>
            <span>{{item.yyzzNo?item.yyzzNo:'-'}}</span>
        </div>
        <div class="card-item">
            <label>服务公司：</label>
            <span>{{item.ocompanyName?item.ocompanyName:'-'}}</span>
        </div>
        <div class="card-item">
            <label>注册时间：</label>
            <span>{{item.registerDate?item.registerDate:'-'}}</span>
        </div>
    </div>
    </el-card>
  </el-card>
</template>
<script>
import api from "@/api/list";
export default {
  data() {
    return {
        individualBusinessList:[],
    };
  },
  mounted() {
    this.getIndividualBusiness();
  },
  methods: {
    getIndividualBusiness() {
      api.getIndividualBusiness().then((res) => {
        if (res.status == "200") {
          this.individualBusinessList = res.data;
        }
      });
    },
    handleClick(item){
        this.$router.push({path:'/userCenter/individualResult',query: {individualId: item.id}})
    }
  },
};
</script>
<style lang="less" scoped>
.card-all {
  padding: 10px 20px;
  margin: 12px 0;
  .card-certification {
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    padding: 8px 0;
    :nth-child(1) {
      color: #333;
    }
    :nth-child(2) {
      color: #8493fa;
    }
  }
  .card-item {
    font-size: 14px;
    color: #999999;
    padding: 6px 0;
  }
}
</style>
